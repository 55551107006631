<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar"
            :docsName="docsName"
    />
    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
            ></head-menu>
            <div class="row mt-2 d-flex justify-content-between">
              <div class="ml-4 mr-2">
                <button
                  class="btn btn-success mr-2"
                  @click="speichern"
                  @shortkey="speichern"
                  :disabled="isExported"
                  v-if="editable"
                >
                  {{ $t("global.save") }}
                </button>

                <button class="btn btn-primary" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />{{
                    $t("global.tolist")
                  }}
                </button>
              </div>
              <div class="mr-4">
                <button
                  v-if="berechtigungen.m_finanzen.delete"
                  class="btn btn-danger"
                  @click="oeffneLoeschenModal"
                  :disabled="isExported"
                >
                  <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
                  <span>{{ $t("global.delete") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- START Linke Seite -->
      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Stammdaten -->
        <container-headline
          :headline="$t('global.masterdata')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="beleg.belegnummer"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.receiptnum") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <span class="openIcon" @click="openDebitor"
                      ><font-awesome-icon
                        icon="fa-duotone fa-arrow-circle-right"
                      />
                    </span>
                    <input
                      v-model="beleg.debitor.debitornummer"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.debtor") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <span class="openIcon" @click="openAuftrag"
                      ><font-awesome-icon
                        icon="fa-duotone fa-arrow-circle-right"
                      />
                    </span>
                    <input
                      v-model="beleg.auftrag.auftragsnummer"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.ordernum") }}</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <date-picker
                      :disabled="!editable"
                      date-format="dd.mm.yy"
                      :initial="beleg.date"
                      placeholder=" "
                      :show-icon="true"
                      @update="(val) => (beleg.date = val)"
                    />
                    <label>{{ $t("global.date") }}</label>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      :disabled="!editable"
                      v-model="beleg.belegtyp"
                      label="bezeichnung"
                      :options="belegtypen"
                      :placeholder="$t('global.receipttype')"
                      :clearable="false"
                    >
                    </v-select>
                    <label>{{ $t("global.receipttype") }}</label>
                  </div>
                </div>

                <div class="col-xl-12">
                  <div class="form-group">
                    <textarea
                      v-model="beleg.buchungstext"
                      class="form-control"
                      placeholder=" "
                      rows="4"
                      :disabled="isExported || !editable"
                    ></textarea>
                    <label>{{ $t("global.bookingtext") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Stammdaten -->
        <!-- START Finanzbuchhaltung -->
        <container-headline
          :headline="$t('global.financialaccounting')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <!--
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="beleg.belegtyp.buchungstext"
                      class="form-control"
                      placeholder=" "
                      :disabled="!editable"
                    />
                    <label>Buchungstext</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="beleg.buchungstext2"
                      class="form-control"
                      placeholder=" "
                      :disabled="!editable"
                    />
                    <label>Buchungstext 2</label>
                  </div>
                </div>
                -->
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="beleg.exportdatum"
                      class="form-control"
                      placeholder=" "
                      readonly
                    />
                    <label>{{ $t("global.exportdate") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Finanzbuchhaltung -->
      </div>
      <!-- ENDE linke Seite -->
      <!-- START Rechte Seite -->
      <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
        <!-- START Kontierung -->
        <container-headline
          :headline="$t('global.accountassignment')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-2">
                  <div class="form-group">
                    <input
                      v-model="beleg.sollkonto.kontonummer"
                      class="form-control"
                      readonly
                    />
                    <label>{{ $t("global.debitaccount") }}</label>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-if="!editable"
                      v-model="beleg.sollkonto.bezeichnung"
                      class="form-control"
                      readonly
                      placeholder=" "
                    />
                    <v-select
                      v-if="editable"
                      v-model="beleg.sollkonto"
                      label="bezeichnung"
                      :options="konten"
                      :placeholder="$t('global.debitaccount')"
                      :clearable="false"
                    >
                    </v-select>
                  </div>
                </div>

                <div class="col-xl-2">
                  <div class="form-group">
                    <input
                      v-model="beleg.habenkonto.kontonummer"
                      class="form-control"
                      readonly
                    />
                    <label>{{ $t("global.creditaccount") }}</label>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-if="!editable"
                      v-model="beleg.habenkonto.bezeichnung"
                      class="form-control"
                      :placeholder="$t('global.account')"
                      readonly
                    />

                    <v-select
                      v-if="editable"
                      v-model="beleg.habenkonto"
                      label="bezeichnung"
                      :options="konten"
                      :placeholder="$t('global.creditaccount')"
                      :clearable="false"
                    >
                    </v-select>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-2">
                  <div class="form-group">
                    <input
                      v-model="beleg.kostenstelle.kostenstellennummer"
                      class="form-control"
                      readonly
                    />
                    <label>{{ $t("global.costcenter") }}</label>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-if="!editable"
                      v-model="beleg.kostenstelle.bezeichnung"
                      class="form-control"
                      readonly
                      placeholder=" "
                    />
                    <v-select
                      v-if="editable"
                      v-model="beleg.kostenstelle"
                      label="bezeichnung"
                      :options="kostenstellen"
                      :placeholder="$t('global.costcenter')"
                      :clearable="false"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Kontierung -->
        <!-- START Betrag -->
        <container-headline
          :headline="$t('global.amount')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-if="!editable"
                      v-model="beleg.waehrung.bezeichnung"
                      class="form-control"
                      placeholder=" "
                      :disabled="!editable"
                    />
                    <v-select
                      v-if="editable"
                      v-model="beleg.waehrung"
                      label="bezeichnung"
                      :options="waehrungen"
                      :placeholder="$t('global.currency')"
                      :clearable="false"
                    >
                    </v-select>
                    <label>{{ $t("global.currency") }}</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="beleg.betrag"
                      class="form-control text-right"
                      placeholder=" "
                      :disabled="!editable"
                    />
                    <label>{{ $t("global.amount") }}</label>
                  </div>
                </div>
                <!--
                <div class="col-xl-6">
                  <div class="form-group">
                    <v-select
                      v-model="beleg.mwstcode"
                      :options="mwstCodes"
                      label="bezeichnung"
                      :disabled="!editable"
                      placeholder="MwSt.-Code"
                    >
                      <span slot="no-options">Kein MwSt.-Code gefunden</span>
                    </v-select>
                    <label>MwSt.-Code</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="beleg.netto"
                      class="form-control text-right"
                      placeholder=" "
                      :disabled="!editable"
                    />
                    <label>Netto</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="beleg.mwst"
                      class="form-control"
                      placeholder=" "
                      :disabled="!editable"
                    />
                    <label>MwSt.</label>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="form-group">
                    <input
                      v-model="beleg.brutto"
                      class="form-control text-right"
                      placeholder=" "
                      :disabled="!editable"
                    />
                    <label>Brutto</label>
                  </div>
                </div>
                -->
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Betrag -->
        <!-- START Zusatzangaben -->
        <container-headline
          :headline="$t('global.additionalinformation')"
          :col="6"
        ></container-headline>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <!-- <div class="col-xl-6 mt-2">
                  <div class="form-group">
                    <input
                      v-model="beleg.belegtext"
                      class="form-control mt-2 mb-3"
                      placeholder=" "
                      :disabled="!editable"
                    />
                    <label class="t-8">Belegtext</label>
                  </div>
                </div> -->

                <div class="col-xl-12">
                  <div class="form-group">
                    <textarea
                      v-model="beleg.bemerkung"
                      class="form-control"
                      placeholder=" "
                      rows=""
                      :disabled="!editable"
                    ></textarea>
                    <label>{{ $t("global.comment") }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ENDE Zusatzangaben -->
      </div>
      <!-- ENDE rechte Seite -->
    </div>

    <div id="modal-loeschen" class="modal fade">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4>
              <font-awesome-icon
                icon="fa-regular fa-warning"
                class="mr-2 text-warning"
              />{{ $t("global.deletereceipt") }}
            </h4>
          </div>
          <div class="modal-body">
            {{ $t("global.doyourwantdeletereceipts") }}
          </div>
          <div class="modal-footer">
            <button class="btn btn-default" @click="schliesseLoeschenModal">
              {{ $t("global.cancel") }}
            </button>
            <button class="btn btn-primary" @click="loeschen">
              {{ $t("global.delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <change-watcher-alert />
  </div>
</template>

<script>
import HeadMenu from "@/components/HeadMenu";

import ContainerHeadline from "@/components/ContainerHeadline";
import Navbar from "@/components/Navbar";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import DatePicker from "@/components/Datepicker";
import Api from "@/Api";
import store from "@/store";
import page from "@/mixins/Page";
import FileDownload from "js-file-download";

import { LADE_AUSWAHLWERTE_BELEG } from "@/store/beleg/actions.type";
import { LADE_AUSWAHLWERTE_ARTIKEL } from "@/store/artikel/actions.type";

import ChangeWatcher from "@/mixins/ChangeWatcher";
import ChangeWatcherAlert from "@/components/global/ChangeWatcherAlert.vue";

export default {
  name: "Beleg",
  components: {
    HeadMenu,
    Navbar,
    ContainerHeadline,
    ChangeWatcherAlert,
    DatePicker,
    //LoadingOverlay,
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
    /* kategorieProp: {
      type: String,
      default: null,
    },*/
  },
  data() {
    return {
      belege: [],
      loading: false,
      editable: false,
      beleg: {
        debitor: {},
        belegtyp: {},
        sollkonto: {},
        habenkonto: {},
        waehrung: {},
        auftrag: {},
        kostenstelle: {},
      },
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.beleg) {
        if (this.kategorieObj)
          return `${this.kategorieObj.bezeichnung} ${this.beleg.belegnummer}`;
        return `${this.$t("global.receipt")} ${this.beleg.belegnummer}`;
      } else {
        if (this.kategorieObj)
          return `${this.$t("global.New")} ${this.kategorieObj.bezeichnung}`;
        return this.$t("global.newreceipt");
      }
    },
    belegtypen: {
      get() {
        return this.$store.state.beleg.belegtypen;
      },
    },
    konten: {
      get() {
        return this.$store.state.beleg.konten;
      },
    },
    waehrungen: {
      get() {
        return this.$store.state.beleg.waehrungen;
      },
    },
    kostenstellen: {
      get() {
        return this.$store.state.artikel.kostenstellen;
      },
    },
    mwstCodes: {
      get() {
        return this.$store.state.artikel.mehrwertsteuercodes;
      },
    },
    isExported: function () {
      if (this.beleg.exportdatum) {
        return true;
      } else return false;
    },
  },

  created() {
    if (this.id) {
      Api.get("finanzen/beleg/", { params: { id: this.id } }).then(
        (response) => {
          this.initializeBeleg(response.data);
        }
      );
      /*Api.get("finanzen/beleg/").then(
      (response) => (this.korrespondenzen = response.data)
    );*/
    }
    if (!this.beleg.exportdatum) {
      this.$store.dispatch(`artikel/${LADE_AUSWAHLWERTE_ARTIKEL}`);
      this.$store.dispatch(`beleg/${LADE_AUSWAHLWERTE_BELEG}`);
    }
    if (!this.id && !this.beleg.exportdatum) {
      this.$store.dispatch(`beleg/${LADE_AUSWAHLWERTE_BELEG}`).then(() => {
        this.beleg.belegtyp = this.belegtypen.find(
          (el) => el.bezeichnung === "Korrekturbuchung"
        );
        this.beleg.waehrung = this.waehrungen.find(
          (el) => el.bezeichnung === "CHF"
        );
      });
      this.editable = this.berechtigungen.m_finanzen.update;
    }
  },
  mounted: function () {},
  methods: {
    abbrechen() {
      this.$router.push({ name: "Belege" });
    },
    initializeBeleg(beleg) {
      if (beleg) {
        this.beleg = beleg;
        if (!beleg.sollkonto)
          beleg.sollkonto = { bezeichnung: this.$t("global.notlinked") };
        if (!beleg.habenkonto)
          beleg.habenkonto = { bezeichnung: this.$t("global.notlinked") };
        if (!beleg.debitor)
          beleg.debitor = { debitornummer: this.$t("global.notlinked") };
        if (!beleg.belegtyp)
          beleg.belegtyp = { bezeichnung: this.$t("global.notlinked") };
        if (this.beleg.belegtyp.bezeichnung == "Korrekturbuchung") {
          this.editable = true;
        }
        if (!beleg.waehrung)
          beleg.waehrung = { bezeichnung: this.$t("global.notlinked") };
        if (!beleg.auftrag)
          beleg.auftrag = { nummer: this.$t("global.notlinked") };
        if (!beleg.kostenstelle)
          beleg.kostenstelle = { bezeichnung: this.$t("global.notlinked") };

        this.initChangeWatcher(this.beleg);
      } else {
        //if (this.editable == false) console.error("beleg ist null");
      }

      this.editable = !this.berechtigungen.m_finanzen.update
        ? false
        : this.editable;
    },

    abacusExport() {
      if (this.id) {
        Api.post("fibuexport/", this.beleg)
          .then((response) => {
            console.log(response);
            FileDownload(response.data, "Abacus-Export.xml");
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.receiptexportedsuccessfully"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.receiptisnotyetrecorded"),
        });
      }
    },

    speichern() {
      if (this.loading) return;

      this.loading = true;
      var json = Object.assign({}, this.beleg);
      json.betrag = json.betrag ? Number(json.betrag) : 0;
      json.brutto = json.brutto ? Number(json.brutto) : 0;
      json.netto = json.netto ? Number(json.netto) : 0;

      if (!this.id) {
        Api.post("finanzen/beleg/", json)
          .then((response) => {
            this.initializeBeleg(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.receiptsuccessfullysaved"),
            });

            this.$router.replace({
              name: "BelegMitId",
              params: { id: response.data.id },
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        Api.put("finanzen/beleg/", json, { params: { id: this.id } })
          .then((response) => {
            this.initializeBeleg(response.data);
            this.$notify({
              type: "success",
              title: this.$t("global.actionsuccessful"),
              text: this.$t("global.receiptsuccessfullysaved"),
            });
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    oeffneLoeschenModal() {
      $("#modal-loeschen").modal("show");
    },
    schliesseLoeschenModal() {
      $("#modal-loeschen").modal("hide");
    },
    loeschen() {
      Api.delete("finanzen/beleg/", {
        params: { id: this.id },
      }).then(() => {
        this.schliesseLoeschenModal();
        this.$router.push({ name: "Belege" });
      });
    },
    openAuftrag() {
      this.$router.push({
        name: "Auftrag",
        params: { id: this.beleg.auftrag.id },
      });
    },
    openDebitor() {
      this.$router.push({
        name: "Debitor",
        params: { id: this.beleg.debitor.id },
      });
    },
  },
};
</script>

<style></style>
